import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
    "& .MuiPaper-root": {
      border: "1px solid rgba(0, 0, 0, 0.30) !important",
    },
    "& .MuiDivider-root": {
      borderColor: "rgba(0, 0, 0, 0.12) !important",
    },
  },
  detailsHeader: {
    fontSize: "24px!important",
    fontWeight: "400!important",
    fontHeight: "133.4%!important",
    fontFamily: "AvenirNext !important",
  },
  projectCardHeader: {
    fontWeight: "500 !important",
    fontSize: "15px !important",
    width: "100%",
  },
  projectCardHeaderAgentName: {
    fontWeight: "500 !important",
    fontSize: "15px !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden  !important",
    textOverflow: "ellipsis  !important",
    width: "90% !important",
  },
  projectCardDes: {
    display: "-webkit-box !important",
    "-webkit-line-clamp": "1 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
  },
  modalHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailsHeaderDueIN: {
    fontSize: "22px!important",
    fontWeight: "400!important",
    fontHeight: "133.4%!important",
    fontFamily: "AvenirNext !important",
  },
  taskMemberBox: {
    alignItems: "flex-start",
    padding: "8px 0px",
    margin: "0px",
    position: "absolute",
    top: "38px",
    right: "-4px",
    background: "#FFFFFF",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    height: "264px",
    overflowY: "scroll",
    zIndex: "9999",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  taskDetailArea: {
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  detailsHeaderTask: {
    fontSize: "22px!important",
    fontWeight: "600!important",
    fontHeight: "133.4%!important",
    fontFamily: "AvenirNext !important",
  },
  otherHeader: {
    fontWeight: "500!important",
    fontSize: "20px!important",
    fontFamily: "AvenirNext !important",
  },
  otherSubHeader: {
    fontWeight: "400!important",
    fontSize: "13px!important",
    fontFamily: "AvenirNext !important",
  },
  overDueDes: {
    display: "-webkit-box !important",
    "-webkit-line-clamp": "1 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
    fontSize: "12.5px !important",
  },
  button: {
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
    height: "37px!important",
    width: "100%!important",
    marginRight: "10px!important",
    fontFamily: "AvenirNext !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    letterSpacing: "0.46px",
    "&:hover": {
      background: "#0B0909!important",
    },
  },
  taskListItem: {
    padding: "10px 13px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px !important",
    // flexWrap: "wrap",
  },
  taskList: {
    height: "400px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 10,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#C0C0C0",
      borderRadius: 10,
    },
  },
  taskListItemButton: {
    borderRadius: "8px !important",
    minWidth: "32px !important",
    width: "32px !important",
    height: "32px !important",
    backgroundColor: "#E4E4E4 !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#e0dcdc !important",
    },
  },
  taskListItemDetail: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12) !important",
    padding: "10px 13px !important",
  },
  taskListItemDetailHeading: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  taskListItemDetailBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  taskListItemDetailDescription: {
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    padding: "7px",
    paddingRight: "15px !important",
    wordBreak: "break-word  !important",
    fontSize: "13px !important",
    width: "100%",
    marginTop: "5.5px  !important",
  },
  taskListItemAttachment: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  taskListItemAttachmentLeft: {
    width: "100%",
    backgroundColor: "#F5F5F5",
    padding: "10px",
    borderRadius: "8px",
    marginTop: "6px",
    display: "flex",
    justifyContent: "flex-start",
  },
  taskListItemAttachmentLeftImages: {
    width: "92%",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "10px 7px",
    overFlowX: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  taskListItemAttachmentLeftImagesItem: {
    width: "70px",
    height: "86px",
    cursor: "pointer",
    border: "1px solid lightgrey",
    backgroundColor: "white  !important",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  taskListItemAttachmentLeftDownloadButton: {
    width: "8%",
    display: "flex",
    justifyContent: "flex-end",
  },
  taskListItemAttachmentLeftDownloadButtonDown: {
    minWidth: "30px !important",
    width: "30px !important",
    height: "30px !important",
    borderRadius: "8px !important",
    backgroundColor: "white !important",
  },
  taskListItemAttachmentRight: {
    width: "10%",
    display: "flex",
    justifyContent: "flex-end",
  },
  taskListItemDes: {
    fontSize: "14px !important",
    backgroundColor: "#ececec !important",
    display: "inline !important",
    padding: "2px !important",
    borderRadius: "8px !important",
  },
  taskListItemHeading: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  projectButton: {
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
    height: "37px!important",
    width: "100%!important",
    marginRight: "10px!important",
    fontFamily: "AvenirNext !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    borderRadius: "30px !important",
    boxShadow: "none !important",
    letterSpacing: "0.46px",
    marginTop: "5px !important",
    "&:hover": {
      background: "#0B0909!important",
    },
    padding: "10px 0px !important",
  },
  historyButton: {
    width: "100%!important",
    fontSize: "12px!important",
    color: "#0B0909!important",
    fontFamily: "AvenirNext !important",
    textTransform: "capitalize !important",
  },
  closeButton: {
    width: "100%!important",
    borderColor: "#0B0909!important",
    color: "#0B0909!important",
    fontFamily: "AvenirNext !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    letterSpacing: "0.46px",
    "&:hover": {
      borderColor: "#0B0909!important",
    },
  },
  textAreaForTopRight: {
    width: "100%!important",
    padding: "10px 45px 10px 13px!important",
    border: "1px solid #BEBEBF !important",
    backgroundColor: "#F7F7F9 !important",
    borderRadius: "8px!important",
    fontWeight: "500 !important",
    fontSize: "14px!important",
    lineHeight: "24px!important",
    letterSpacing: "0.15px!important",
    overflowY: "auto !important",
    fontFamily: "AvenirNext !important",
    fontStyle: "normal !important",
    height: "330px !important",
    marginBottom: "10px  !important",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  textAreaStipulation: {
    width: "100%!important",
    padding: "10px 45px 10px 13px!important",
    borderColor: "rgba(0, 0, 0, 0.23)!important",
    borderRadius: "8px!important",
    fontWeight: "400!important",
    fontSize: "14px!important",
    lineHeight: "24px!important",
    letterSpacing: "0.15px!important",
    overflowY: "auto !important",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  textArea: {
    width: "100%!important",
    padding: "10px 13px!important",
    borderColor: "rgba(0, 0, 0, 0.23)!important",
    borderRadius: "8px!important",
    fontWeight: "400!important",
    fontSize: "14px!important",
    lineHeight: "24px!important",
    letterSpacing: "0.15px!important",
    overflowY: "auto !important",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  textArea2: {
    width: "100%!important",
    padding: "10px 15px!important",
    height: "202px!important",
    borderColor: "rgba(0, 0, 0, 0.23)!important",
    borderRadius: "8px!important",
    fontWeight: "400!important",
    fontSize: "14px!important",
    lineHeight: "24px!important",
    letterSpacing: "0.15px!important",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  staffNoteArea: {
    position: "relative",
    width: "100%!important",
    padding: "0px 0px 0px 15px!important",
    height: "200px!important",
    border: "1px solid rgba(0, 0, 0, 0.23)!important",
    borderRadius: "8px!important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  chatCommentsBox: {
    height: "160px!important",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  staffNoteText: {
    position: "absolute!important",
    bottom: "-3px!important",
    left: "0px",
    width: "100%",
    height: "40px!important",
    "& .MuiInput-input": {
      padding: "8px 15px!important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 15px!important",
    },
    "& label.Mui-focused": {
      color: "rgba(0, 0, 0, 0.23)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(0, 0, 0, 0.23)",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
  deliverablesImg: {
    width: "40px",
    height: "50px",
    border: "1px solid #000",
  },
  deliverablesImgContainer: {
    display: "flex",
    gap: "7px",
  },
  statusBox: {
    zIndex: 999999,
    alignItems: "flex-start",
    padding: "8px 0px",
    margin: "0px",
    position: "absolute",
    top: "25px",
    right: "-30px",
    background: "transparent",
  },
  statusBoxItem: {
    width: "100%",
    display: "flex !important",
    justifyContent: "center  !important",
    borderRadius: "20px   !important",
    padding: "5px 0px !important",
  },
}));
