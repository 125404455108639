import React from "react";
import { Chip } from "@mui/material";
import { capitalize, statusColor } from "./helpers";

const StatusComponent = (props) => {
  const { status, height, bgColor } = props;
  return (
    <Chip
      {...props}
      sx={{
        borderRadius: "24px !important",
        height: height ? `${height} !important` : "38px!important",
        background: bgColor ? bgColor : `${statusColor(status)}`,
        "& .MuiChip-label": {
          color: "#fff!important",
          fontSize: "15px!important",
          width: "100%!important",
          textAlign: "center",
        },
        "&:hover": {
          background: `${statusColor(status)}`,
        },
        ...props.sx,
      }}
      label={status ? capitalize(status === "onhold" ? "On Hold" : status) : ""}
    />
  );
};

export default StatusComponent;
