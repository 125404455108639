export const styleSheet = (theme) => ({
  transactionFormCard: {
    marginTop: "20px",
    padding: "15px 30px",
  },
  FormContractTypeBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "15px 0px",
    width: "100%",
  },
  FormContractTypeBoxRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginLeft: "40px",
  },
  transactionFormCardHeading: {
    fontSize: "24px !important",
    fontFamily: "AvenirNext  !important",
    fontWeight: "400 !important",
    whiteSpace: "nowrap !important",
  },
  transactionFormCardTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  transactionFormArea: {
    marginTop: "15px !important",
  },
  saveModalButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  CloseContractHeading: {
    fontSize: "22px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  loadingArea: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  agentAndMarketSec: {
    display: "flex !important",
    gap: "10px !important",
  },
});
