import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getAlerts = createAsyncThunk(
  "MessagesAndAlerts/getAlerts",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/notifications/alerts?pageNumber=${
          data?.pageNumber || 1
        }&pageSize=${data?.pageSize || 10}&sortOrder=desc&showUnreadedOnly=${
          data.showUnreadedOnly
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return {
        ...resp.data,
        pageNumber: data?.pageNumber,
        isNewAlert: data?.isNewAlert,
      };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
