import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EventIcon from "@mui/icons-material/Event";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import GppGoodIcon from "@mui/icons-material/GppGood";
import CelebrationIcon from "@mui/icons-material/Celebration";
import AlertSkelton from "./skeltons/alerts.js";
import Skeleton from "@mui/material/Skeleton";
import { getAlerts, removeAlert } from "../../redux/messageAndAlerts";
import { markReadNotificationsAll } from "../../redux/messageAndAlerts/markReadAll.js";
import { useDispatch, useSelector } from "react-redux";
import momentTZ from "moment-timezone";
import Linkify from "react-linkify";
import { useNavigate } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import { Link } from "react-router-dom";
import { CustomTooltip } from "../shared/tooltip.js";
import { updateData } from "../../utils/updateData";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircleIcon from "@mui/icons-material/Circle";
import PriceCheckRoundedIcon from "@mui/icons-material/PriceCheckRounded";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0.5 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Icons = {
  lead_claim: (
    <PriceCheckRoundedIcon sx={{ fontSize: "27px", color: "#4CAF50" }} />
  ),
  shared_property_via_email: (
    <PriceCheckRoundedIcon sx={{ fontSize: "27px", color: "#4CAF50" }} />
  ),
};
let IconList = [
  {
    icon: <ErrorOutlineIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
  {
    icon: <EventIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
  {
    icon: <OndemandVideoIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
  {
    icon: <GppGoodIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
  {
    icon: <GppGoodIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
  {
    icon: <CelebrationIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
];
function AlertWidget(props) {
  let {
    classes,
    openAlert,
    setOpenAlert,
    adminAuth,
    unreadAlertCount,
    setUnreadAlertCount,
  } = props;
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
  });
  const [value, setValue] = React.useState(0);
  const [showUnReads, setShowUnReads] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.MessagesAndAlerts.alerts);

  useEffect(() => {
    if (openAlert) {
      dispatch(
        getAlerts({
          ...filters,
          showUnreadedOnly: showUnReads,
        })
      );
    }
  }, [openAlert, filters.pageNumber, showUnReads]);

  const handleRedirect = (alertDetail) => {
    if (alertDetail?.lead_id) {
      navigate(`/leads/people/details/${alertDetail?.lead_id}`);
    }
    if (alertDetail?.recruitment_id) {
      navigate(`/tools/recruiting/details/${alertDetail?.recruitment_id}`);
    }
    if (alertDetail?.project_id) {
      navigate(`/projects`, {
        state: { project_id: alertDetail?.project_id },
      });
    }
    if (alertDetail?.task_id) {
      navigate(`/projects`, {
        state: { task_id: alertDetail?.task_id },
      });
    }
    if (alertDetail?.ticket_id) {
      navigate(`/agent_tickets/assigned`, {
        state: { ticket_id: alertDetail?.ticket_id },
      });
    }
    setOpenAlert(false);
  };
  const handleUpdateStatus = (id) => {
    updateData(
      `${process.env.REACT_APP_BASE_URL}api/notifications/alert/${id}`,
      { is_seen: true, seen_at: new Date().toISOString() }
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMarkAsRead = () => {
    dispatch(markReadNotificationsAll());
    if (unreadAlertCount > 0) {
      setTimeout(() => {
        setUnreadAlertCount(0);
      }, 2000);
    }
  };

  return (
    <Box className={classes.alertsArea}>
      <Box className={classes.AlertAreaHeader}>
        <Typography>Alerts</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={showUnReads}
              onChange={(e) => setShowUnReads(e.target.checked)}
            />
          }
          label="Show only unread alerts"
          labelPlacement="start"
          sx={{
            "& .MuiTypography-root": {
              fontSize: "14px !important",
              color: "rgba(0, 0, 0, 0.60)  !important",
            },
          }}
        />
        {/* <Stack direction={"row"} spacing={1}>
          {alerts.isLoading ? null : (
            <IconButton size="small" sx={{ padding: "2px" }}>
              <HighlightOffRoundedIcon
                sx={{ fontSize: "20px" }}
                fontSize="medium"
              />
            </IconButton>
          )}
          {alerts.isLoading ? (
            <Box className={classes.unreadBadgeText}>
              <Skeleton animation="wave" height={20} width="60%" />
            </Box>
          ) : (
            <Box className={classes.unreadBadgeText}>
              {unreadAlertCount || 0} Unseen
            </Box>
          )}
        </Stack> */}
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="inherit" // Use inherit to control color via `sx`
            TabIndicatorProps={{
              style: {
                backgroundColor: "black", // Black indicator
              },
            }}
            sx={{
              "& .MuiTab-root": {
                textTransform: "none", // Camel case (prevents uppercase text)
                fontSize: "0.875rem", // Smaller font size
                minWidth: "80px", // Adjust tab size
                padding: "6px 12px",
              },
            }}
          >
            <Tab
              label={
                <Box>
                  {" "}
                  <CircleIcon
                    fontSize="small"
                    sx={{ color: "red", fontSize: "10px", mr: "7px" }}
                  />
                  Direct
                </Box>
              }
              {...a11yProps(0)}
            />
            <Tab label="Watching" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box className={classes.tabSubHeader}>
            <Typography className={classes.markAsHeading}>LATEST</Typography>
            <Button
              variant="text"
              size="small"
              className={classes.markAllAsRead}
              onClick={handleMarkAsRead}
            >
              Mark all as read
            </Button>
          </Box>
          <List disablePadding className={classes.textMessagesList}>
            {alerts?.data?.length
              ? alerts?.data.map((item, index) => (
                  <ListItem key={index} divider disablePadding>
                    <ListItemButton
                      sx={{
                        paddingRight: "4px",
                        "&:hover": {
                          "& .closeActionItem": { display: "block !important" },
                        },
                      }}
                    >
                      <ListItemAvatar
                        onClick={() => handleRedirect(item)}
                        sx={{ minWidth: "40px" }}
                      >
                        {IconList[index]?.icon || IconList[3]?.icon}
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ width: "80%" }}
                        onClick={() => handleRedirect(item)}
                        classes={{
                          secondary: classes.textMessagesListItemContent,
                        }}
                        primary={
                          <Stack
                            direction={"row"}
                            spacing={1}
                            alignItems={"center"}
                          >
                            {" "}
                            <Typography
                              className={classes.alertListItemHeading}
                            >
                              {item.title}
                            </Typography>{" "}
                            <Typography
                              className={classes.textMessagesListItemTime}
                            >
                              {momentTZ(item.updatedAt).fromNow()}
                            </Typography>
                          </Stack>
                        }
                        secondary={
                          <Linkify
                            properties={{ target: "_blank" }}
                            componentDecorator={(
                              decoratedHref,
                              decoratedText,
                              key
                            ) => (
                              <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                              </a>
                            )}
                          >
                            {reactStringReplace(
                              item?.body || "",
                              /@\[(.*?)]/g,
                              (match, i) => (
                                <CustomTooltip
                                  title={match?.split("^")?.[0]}
                                  placement="top"
                                >
                                  <Box
                                    sx={{
                                      color:
                                        match?.split("^")?.[1] ===
                                        adminAuth?.adminDetail.id
                                          ? "#007EA6 !important"
                                          : "#434343 !important",
                                      background:
                                        match?.split("^")?.[1] ===
                                        adminAuth?.adminDetail.id
                                          ? "#CCF3FF !important"
                                          : "#ECECEC !important",
                                      display: "inline-block",
                                      textDecoration: "none",
                                    }}
                                    key={i}
                                    to={`/agent_roster/${
                                      match?.split("^")?.[1] || 69
                                    }/overview`}
                                    component={Link}
                                  >
                                    @{match?.split(" ")?.[0]}
                                  </Box>
                                </CustomTooltip>
                              )
                            )}
                          </Linkify>
                        }
                      />
                      {item.is_seen ? null : (
                        <ListItemSecondaryAction
                          className="closeActionItem"
                          sx={{ mr: "-5px" }}
                        >
                          <IconButton
                            onClick={() => {
                              dispatch(removeAlert({ id: item.id }));
                              handleUpdateStatus(item.id);
                            }}
                            size="small"
                            title="Mark as read"
                          >
                            <CircleIcon
                              fontSize="small"
                              sx={{ color: "red", fontSize: "10px" }}
                            />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  </ListItem>
                ))
              : null}
            {alerts.isLoading ? <AlertSkelton /> : null}
            {!alerts?.data?.length && !alerts.isLoading ? (
              <Box className={classes.TextMessageAreaNoExist}>
                <center>
                  <ErrorOutlineIcon
                    sx={{ color: "rgba(0, 0, 0, 0.4)", fontSize: "60px" }}
                  />
                  <Typography>No Unseen Alerts</Typography>
                </center>
              </Box>
            ) : null}
          </List>
          {alerts.count > filters.pageNumber * filters.pageSize ? (
            <Box>
              <Button
                variant="text"
                size="small"
                fullWidth
                onClick={() =>
                  setFilters({ ...filters, pageNumber: filters.pageNumber + 1 })
                }
                sx={{
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
                disabled={alerts.isLoading}
              >
                See More
              </Button>
            </Box>
          ) : null}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "200px",
            }}
          >
            <CelebrationIcon sx={{ fontSize: "50px", color: "grey" }} />
            <Typography> Coming soon</Typography>
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "AlertWidgetStyle" })(
  AlertWidget
);
