import * as React from "react";
import { Button, Box, Dialog, DialogContent, Typography } from "@mui/material";
export default function TaskConfirmation(props) {
  const { setOpen, open, handleConfirm, title, description } = props;

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      disablePortal
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogContent sx={{ padding: "50px" }}>
        <Typography
          sx={{
            color: "black",
            fontSize: "20px",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ margin: "15px 0px", fontSize: "15px", textAlign: "center" }}
        >
          {description}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            marginTop: "30px",
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{ color: "black", borderColor: "black !important" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="info"
            sx={{ background: "black", color: "white" }}
          >
            Confirm
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
