import React, { useState, useContext, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Typography,
  Box,
  Divider,
  TextareaAutosize,
  Button,
  AvatarGroup,
  MenuItem,
  DialogTitle,
  Stack,
  IconButton,
  Card,
  CardHeader,
  ListItemButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  ListItemSecondaryAction,
  Chip,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProjectDetailSkelton from "../skelton/taskDetail";
import AvatarComponent from "../AvatarComponent";
import { calculateDueIn, openDeliverable, getSignedURL } from "../helpers";
import {
  changeProjectStatus,
  getProject,
  saveProjectDataFields,
} from "../../../redux/projects/projects";
import {
  saveProjectTaskPublicComments,
  saveProjectTaskMessages,
} from "../../../redux/projects/common";
import moment from "moment";
import StatusComponent from "../StatusComponent";
import OutsideClickHandler from "react-outside-click-handler";
import {
  openTaskDetails,
  resetProject,
  setSelectedTaskId,
  updateTaskStatus,
} from "../../../redux/projects/project";
import {
  changeTaskStatus,
  assignTask,
  unassignTask,
} from "../../../redux/projects/tasks";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CommentSection from "../../../components/shared/commentSection";
import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { toast } from "react-toastify";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import flowerIcon from "../../../assets/images/agents/flowerIcon.png";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import momentTZ from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../components/callPanel/callContext";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import { CustomTooltip } from "../../../components/shared/tooltip";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import Linkify from "react-linkify";
import { marketCustom } from "../../../utils/markets";
import FileSaver from "file-saver";
import JSZip from "jszip";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import TaskConfirmation from "./taskConfirm.tsx";
import { useStyles } from "./style.js";
import { handleReturnTaskStatus } from "../helpers.js";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
export default function ProjectDetails(props) {
  let { openComments, setOpenComments } = props;
  const navigate = useNavigate();
  const { dialNumber } = useContext(AppContext);
  /**
   * Variables and States
   */
  const classes = useStyles();
  const dispatch = useDispatch();
  const scrollRef = React.useRef();
  const emailRef = useRef();
  const agentNameRef = useRef();
  const zip = new JSZip();
  const { open, setOpen, projectID } = props;
  // for comment box

  const [viewHistory, setViewHistory] = useState(false);
  const [creationNote, setCreationNote] = useState(null);
  const [specialStips, setSpecialStips] = useState(null);
  const [publicNote, setPublicNote] = useState("");
  const [teamMemberDropdown, openTeamMemberDropdown] = useState(false);
  const [uniqueTeamMembers, setUniqueTeamMembers] = useState([]);
  const [firstLoad] = useState(true);
  const [statusDialog, setStatusDialog] = useState(false);
  const [taskStatusDialog, setTaskStatusDialog] = useState(false);
  const [projectButton, setProjectButton] = useState(null);
  const [openedTasks, setOpenedTasks] = useState([]);
  const [overflowActive, setOverflowActive] = useState(false);
  const [statusAlert, setStatusAlert] = useState(false);
  const [activeTask, setActiveTask] = useState({});
  const [agentNameOverflowActive, setAgentNameOverflowActive] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const project = useSelector((state) => state.project.project);
  const projectLoading = useSelector((state) => state.project.projectLoading);
  const users = useSelector((state) =>
    state.users.storedListOfUsers.list ? state.users.storedListOfUsers.list : []
  );
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const openStatusDialog = (flag) => {
    setStatusDialog(flag);
  };

  const openTaskStatusDialog = (flag) => {
    setTaskStatusDialog(flag);
  };
  const handleOpenTask = (id) => {
    dispatch(setSelectedTaskId(id));
    dispatch(openTaskDetails(true));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = (status) => {
    dispatch(changeProjectStatus({ projectID, status }));
    openStatusDialog(false);
  };
  const getLastPartOfString = (str) => {
    const parts = str.split("/");
    const lastPart = parts.pop();
    const commaIndex = lastPart.indexOf(",");
    const dotIndex = lastPart.lastIndexOf(".");
    const firstPart =
      commaIndex !== -1 ? lastPart.substring(0, commaIndex) : lastPart;
    const secondPart = dotIndex !== -1 ? lastPart.substring(dotIndex) : "";
    return firstPart + secondPart;
  };
  const downloadDeliverable = async (task_deliverables) => {
    if (task_deliverables.length === 1) {
      toast.promise(
        Promise.all(
          task_deliverables.map((d, index) => {
            return getSignedURL(d.file).then(async (url) => {
              let blobData = await fetch(url.data).then((r) => r.blob());
              FileSaver.saveAs(blobData, getLastPartOfString(d.file));
            });
          })
        ),
        {
          pending: "Downloading selected deliverables is in progress",
          success: "Successfully Downloaded",
          error: "Something went wrong while downloading deliverables",
        }
      );
    } else {
      toast.promise(
        Promise.all(
          task_deliverables.map((d, index) => {
            return getSignedURL(d.file).then(async (url) => {
              await fetch(url.data).then((resp) => {
                let filename = getLastPartOfString(d.file);
                zip.file(filename, resp.blob());
              });
            });
          })
        ).then((z) => {
          zip.generateAsync({ type: "blob" }).then(function (blob) {
            FileSaver.saveAs(blob, "task_deliverables.zip");
          });
        }),
        {
          pending: "Downloading selected deliverables is in progress",
          success: "Successfully Downloaded",
          error: "Something went wrong while downloading deliverables",
        }
      );
    }
  };

  const handleOpenTaskDetail = (item) => {
    if (item?.public_notes || item?.task_deliverables?.length) {
      if (openedTasks?.includes(item.id)) {
        setOpenedTasks((preTaskIds) =>
          [...preTaskIds].filter((id) => id !== item.id)
        );
      } else {
        setOpenedTasks((preTaskIds) => [...preTaskIds, item.id]);
      }
    }
  };
  const handleConfirmCancel = () => {
    handleChangeStatus("canceled");
    setCancelConfirmation(false);
  };
  const handleConfirm = () => {
    dispatch(
      changeTaskStatus({
        taskID: activeTask?.taskId,
        status: activeTask?.status,
        disableProjectFetch: true,
        confirmed: true,
      })
    );
    dispatch(updateTaskStatus(activeTask));
    setStatusAlert(false);
  };

  const handleStatusChange = async (status, taskDetail) => {
    dispatch(updateTaskStatus({ taskId: taskDetail?.id, status }));
    openTaskStatusDialog(false);
    const resp = await dispatch(
      changeTaskStatus({
        taskID: taskDetail?.id,
        status,
        disableProjectFetch: true,
      })
    );
    if (resp?.payload?.status === 409) {
      setActiveTask({ taskId: taskDetail?.id, status });
      setStatusAlert(true);
      dispatch(
        updateTaskStatus({ taskId: taskDetail?.id, status: taskDetail.status })
      );
    }
  };

  const assignTaskMember = (user, taskDetail) => {
    dispatch(
      assignTask({
        task_id: taskDetail.id,
        user_id: user.id,
        user,
        disableFetch: true,
      })
    );
    openTeamMemberDropdown(false);
  };

  const unassignTaskMember = (member_id, taskDetail) => {
    dispatch(
      unassignTask({ task_id: taskDetail.id, member_id, disableFetch: true })
    );
    openTeamMemberDropdown(false);
  };

  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */

  React.useEffect(() => {
    dispatch(getProject(projectID));
    return () => {
      dispatch(resetProject());
    };
  }, [projectID]);

  React.useEffect(() => {
    dispatch(getProject(projectID));
    return () => {
      dispatch(resetProject());
    };
  }, [projectID]);

  React.useEffect(() => {
    if (emailRef.current && emailRef.current?.children[0]) {
      if (isOverflowActive(emailRef.current.children[0])) {
        setOverflowActive(true);
        return;
      }
      setOverflowActive(false);
    }
  }, [project?.agent?.email]);

  React.useEffect(() => {
    if (agentNameRef.current && agentNameRef.current) {
      if (isOverflowActive(agentNameRef.current)) {
        setAgentNameOverflowActive(true);
        return;
      }
      setAgentNameOverflowActive(false);
    }
  }, [project?.agent?.full_name]);

  function isOverflowActive(event) {
    return event?.offsetWidth < event?.scrollWidth;
  }

  React.useEffect(() => {
    const t = [];
    let teams_mem = [],
      team_ids = [];
    if (project && project.project_tasks) {
      for (let member of project?.project_tasks) {
        if (
          member.team_member?.id &&
          !team_ids.includes(member.team_member?.id)
        ) {
          teams_mem.push(member.team_member);
          team_ids.push(member.team_member?.id);
        }
      }
      setUniqueTeamMembers(teams_mem);
    }

    project.project_other_data?.forEach((dataField) => {
      if (dataField.data_field.name === "Notes/Comments") {
        setCreationNote(dataField);
      }
      if (
        dataField.data_field.name === "Special Stipulations" &&
        project.project_name === "New Offer"
      ) {
        setSpecialStips(dataField);
      }
    });

    if (project && project.project_tasks) {
      if (
        !project.project_other_data?.find(
          (item) => item.data_field.name === "Notes/Comments"
        )
      ) {
        let noteData = "";
        let project_other_data = [...project.project_other_data];
        project_other_data.sort((a, b) => a.data_field.id - b.data_field.id);
        project_other_data?.forEach((dataField) => {
          if (noteData) {
            noteData = `${noteData} \n ${dataField.data_field.name}: ${dataField.value}`;
          } else {
            noteData = `${dataField.data_field.name}: ${dataField.value}`;
          }
        });
        setCreationNote({ value: noteData });
      }
    }

    setPublicNote(project.public_notes || "");

    if (project && project?.project_tasks?.length > 0) {
      let firstCondition = true;
      if (project.project_tasks.length > 1)
        project.project_tasks?.map((task) => {
          if (task?.approval_required === true && task?.status !== "approved")
            firstCondition = false;
          else if (
            task?.approval_required === false &&
            task?.status !== "review" &&
            task?.status !== "approved"
          )
            firstCondition = false;
        });
      else if (project.project_tasks[0].status !== "approved")
        firstCondition = false;
      const secondCondition = project.project_tasks?.find(
        (task) =>
          task?.status !== "approved" &&
          task?.status !== "review" &&
          task?.status !== "submitted"
      );
      const allRegularTask = !project.project_tasks?.find(
        (task) => task?.approval_required === true
      );
      const allSubmittedTasks = !project.project_tasks?.find(
        (task) => task?.status !== "submitted" && task?.status !== "approved"
      );
      if (firstCondition) {
        setProjectButton("completed");
      } else if (secondCondition || allSubmittedTasks) {
        setProjectButton("");
      } else if (!secondCondition) {
        setProjectButton("submitted");
      } else if (allRegularTask) {
        if (project.status.toLowerCase() === "approved") {
          setProjectButton("completed");
        } else if (project.status.toLowerCase() === "review") {
          setProjectButton("submitted");
        } else if (
          project.status.toLowerCase() !== "canceled" &&
          project.status.toLowerCase() !== "completed"
        ) {
          setProjectButton("canceled");
        }
      }
    }
  }, [project, project.project_tasks]);

  React.useEffect(() => {
    if (scrollRef?.current && !firstLoad) {
      scrollRef.current.scrollIntoView();
    }
  }, [project?.project_comments?.length]);
  /**
   * End Region: useEffect Hooks
   */

  const handleSubmitComment = (data) => {
    let obj = {
      ...data,
      id: project?.id,
      type: "project",
    };
    dispatch(saveProjectTaskMessages(obj));
  };

  return (
    <div>
      <Dialog
        disablePortal
        sx={{
          "& .MuiDialog-container": { mt: "30px" },
          "& .MuiDialog-paper": {
            backgroundColor: "#FBFBFD !important",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#ddd",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#0B0909",
              borderRadius: 1,
            },
          },
        }}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={openComments ? "lg" : "md"}
      >
        {projectLoading && (
          <DialogContent className={classes.dialogContent}>
            <ProjectDetailSkelton />
          </DialogContent>
        )}
        {!projectLoading && (
          <Grid container spacing={1} className={classes.taskDetailArea}>
            <Grid item xs={12} lg={openComments ? 8.6 : 12}>
              <DialogTitle className={classes.modalHeading}>
                <Typography className={classes.detailsHeader}>
                  Project Detail
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  {!viewHistory ? (
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        openStatusDialog(false);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space",
                          position: "relative",
                        }}
                      >
                        <StatusComponent
                          clickable
                          height="28px"
                          onClick={() =>
                            (project?.status.toLowerCase() === "review" ||
                              project?.status.toLowerCase() ===
                                "ready_to_complete") &&
                            openStatusDialog(!statusDialog)
                          }
                          status={project?.status}
                        />

                        {statusDialog && (
                          <Grid className={classes.statusBox}>
                            <MenuItem
                              className={classes.statusBoxItem}
                              onClick={() => handleChangeStatus("completed")}
                            >
                              <StatusComponent
                                clickable
                                sx={{ width: "100%" }}
                                status={"Completed"}
                              />
                            </MenuItem>
                            <MenuItem
                              className={classes.statusBoxItem}
                              onClick={() => handleChangeStatus("review")}
                            >
                              <StatusComponent
                                clickable
                                sx={{ width: "100%" }}
                                status={"Review"}
                              />
                            </MenuItem>
                          </Grid>
                        )}
                      </Box>
                    </OutsideClickHandler>
                  ) : null}
                  {viewHistory ? (
                    <Button
                      startIcon={<ArrowBackIcon sx={{ color: "grey" }} />}
                      color="inherit"
                      sx={{ textTransform: "capitalize", color: "grey" }}
                      onClick={() => setViewHistory(false)}
                      variant="text"
                    >
                      Back to Project Detail
                    </Button>
                  ) : null}
                  {project?.status !== "canceled" ? (
                    <IconButton
                      onClick={() => setCancelConfirmation(true)}
                      title="Cancel Project"
                    >
                      <CancelRoundedIcon sx={{ fontSize: "1.65rem" }} />
                    </IconButton>
                  ) : null}
                  {viewHistory ? null : (
                    <IconButton
                      sx={{
                        ml: "-2px !important",
                      }} /* onClick={() => setViewHistory(true)} */
                    >
                      <RestoreRoundedIcon sx={{ fontSize: "1.65rem" }} />
                    </IconButton>
                  )}{" "}
                  {viewHistory ? null : (
                    <IconButton
                      sx={{
                        ml: "-2px !important",
                        backgroundColor: openComments
                          ? "rgba(0, 0, 0, 0.04)"
                          : "",
                      }}
                      onClick={() => setOpenComments(!openComments)}
                    >
                      <ChatBubbleOutlineRoundedIcon sx={{ fontSize: "23px" }} />
                    </IconButton>
                  )}
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <IconButton size="small" onClick={() => setOpen(false)}>
                    <ClearRoundedIcon sx={{ fontSize: "1.65rem" }} />
                  </IconButton>
                </Stack>
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={2}>
                  <Grid xs={12} md={5} lg={4.4} item>
                    <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                      <CardHeader
                        CardHeader
                        classes={{
                          title: classes.projectCardHeader,
                          subheader: classes.projectCardDes,
                        }}
                        avatar={
                          project?.visibility === "visible" ? (
                            <CustomTooltip
                              title={"Visible to Agent"}
                              placement="top"
                            >
                              <VisibilityRoundedIcon
                                sx={{
                                  fontSize: "26px",
                                  color: "#1cc600",
                                  cursor: "help",
                                }}
                              />
                            </CustomTooltip>
                          ) : (
                            <CustomTooltip
                              title={"Hidden from Agent"}
                              placement="top"
                            >
                              <VisibilityOffRoundedIcon
                                sx={{
                                  fontSize: "26px",
                                  color: "#ff5f5f",
                                  cursor: "help",
                                }}
                              />
                            </CustomTooltip>
                          )
                        }
                        title={project?.project_name}
                        subheader={project?.title}
                      />
                    </Card>
                    <Card
                      variant="outlined"
                      sx={{ borderRadius: "8px", mt: "10px" }}
                    >
                      <CardHeader
                        CardHeader
                        classes={{
                          title: classes.projectCardHeader,
                          subheader: classes.projectCardDes,
                        }}
                        avatar={
                          <AvatarComponent
                            variant="square"
                            src={
                              project?.agent?.profile_images
                                ?.profile_img_thumbnail
                            }
                            nameInitial={project?.agent?.full_name}
                            sx={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "4px",
                            }}
                          />
                        }
                        title={
                          <CustomTooltip
                            placement="top"
                            title={
                              agentNameOverflowActive
                                ? project?.agent?.full_name
                                : ""
                            }
                          >
                            <Box
                              ref={agentNameRef}
                              className={classes.projectCardHeaderAgentName}
                            >
                              {project?.agent?.full_name}
                            </Box>
                          </CustomTooltip>
                        }
                        subheader={project?.agent?.license_type}
                      />
                      <Divider />
                      <List disablePadding>
                        <ListItem disablePadding sx={{ mt: "4px" }}>
                          <ListItemButton dense>
                            <ListItemIcon sx={{ minWidth: "40px" }}>
                              <PeopleRoundedIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Chip
                                  label={project?.agent?.team?.name}
                                  size="small"
                                  sx={{
                                    bgcolor:
                                      project?.agent?.team?.team_color ||
                                      "transparent",
                                    color: "white",
                                    padding: "6px",
                                    fontSize: "14px",
                                  }}
                                />
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton dense>
                            <ListItemIcon sx={{ minWidth: "40px" }}>
                              <LocationCityRoundedIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Chip
                                  label={project?.agent?.market?.name}
                                  size="small"
                                  sx={{
                                    bgcolor:
                                      project?.agent?.market_id &&
                                      marketCustom.find(
                                        (item) =>
                                          item.id === project?.agent?.market_id
                                      )
                                        ? marketCustom.find(
                                            (item) =>
                                              item.id ===
                                              project?.agent?.market_id
                                          )?.backgroundColor
                                        : marketCustom.find(
                                            (item) =>
                                              item.id ===
                                              project?.agent?.market_id
                                          )?.backgroundColor
                                        ? "#4FC3F7"
                                        : "transparent",
                                    color: "white",
                                    padding: "6px",
                                    fontSize: "14px",
                                  }}
                                />
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem
                          sx={{
                            "&:hover": {
                              "& .MuiListItemSecondaryAction-root": {
                                display: "block !important",
                              },
                            },
                          }}
                          disablePadding
                        >
                          <ListItemButton dense>
                            <ListItemIcon sx={{ minWidth: "40px" }}>
                              <LocalPhoneRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary={project?.agent?.phone} />
                            <ListItemSecondaryAction sx={{ display: "none" }}>
                              <CopyToClipboard
                                onCopy={() =>
                                  toast.success("Copied Successfully!", {
                                    position: "top-right",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  })
                                }
                                text={project?.agent?.phone}
                              >
                                <IconButton sx={{ padding: "3px" }}>
                                  <ContentCopyRoundedIcon fontSize="small" />
                                </IconButton>
                              </CopyToClipboard>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        </ListItem>
                        <ListItem
                          sx={{
                            "&:hover": {
                              "& .MuiListItemSecondaryAction-root": {
                                display: "block !important",
                              },
                            },
                            paddingBottom: "4px",
                          }}
                          disablePadding
                          divider
                        >
                          <ListItemButton dense>
                            <ListItemIcon sx={{ minWidth: "40px" }}>
                              <EmailRoundedIcon />
                            </ListItemIcon>
                            <CustomTooltip
                              placement="top"
                              title={
                                overflowActive ? project?.agent?.email : ""
                              }
                            >
                              <ListItemText
                                sx={{
                                  "& .MuiTypography-root": {
                                    width: "85%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  },
                                }}
                                primary={project?.agent?.email}
                                ref={emailRef}
                              />
                            </CustomTooltip>
                            <ListItemSecondaryAction sx={{ display: "none" }}>
                              <CopyToClipboard
                                onCopy={() =>
                                  toast.success("Copied Successfully!", {
                                    position: "top-right",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  })
                                }
                                text={project?.agent?.email}
                              >
                                <IconButton sx={{ padding: "3px" }}>
                                  <ContentCopyRoundedIcon fontSize="small" />
                                </IconButton>
                              </CopyToClipboard>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Stack
                                onClick={() => {
                                  navigate(
                                    `/agent_roster/${project?.agent_id}/overview`
                                  );
                                }}
                                direction="row"
                                spacing={1}
                                sx={{ cursor: "pointer" }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "12.5px",
                                    "&:hover": { textDecoration: "underline" },
                                  }}
                                >
                                  View Agent Detail
                                </Typography>
                                <EastRoundedIcon fontSize="small" />
                              </Stack>
                            }
                          />
                          <ListItemSecondaryAction sx={{ mr: "-8px" }}>
                            <Stack direction="row" spacing={1}>
                              <Button
                                sx={{
                                  minWidth: "30px",
                                  width: "30px",
                                  height: "28.5px",
                                  borderRadius: "6px",
                                  padding: "0px 5px",
                                }}
                                variant="outlined"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  if (project?.agent?.phone) {
                                    dialNumber(
                                      project?.agent?.phone,
                                      null,
                                      project?.agent?.full_name,
                                      null,
                                      project?.agent_id
                                    );
                                  }
                                }}
                              >
                                {" "}
                                <LocalPhoneRoundedIcon
                                  sx={{ fontSize: "18px !important" }}
                                  fontSize="small"
                                />
                              </Button>
                              <Button
                                sx={{
                                  minWidth: "30px",
                                  height: "28.5px",
                                  borderRadius: "6px",
                                  padding: "3px 5px",
                                }}
                                variant="outlined"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  if (
                                    project?.agent?.admin_channel_id &&
                                    project?.agent?.slack_team_id
                                  ) {
                                    window.open(
                                      `slack://channel?team=${project?.agent?.slack_team_id}&id=${project?.agent?.admin_channel_id}`
                                    );
                                  }
                                }}
                              >
                                {" "}
                                <img
                                  style={{ width: "15.3px" }}
                                  src={flowerIcon}
                                  alt="badge icon"
                                />
                              </Button>
                            </Stack>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </Card>
                    <Card
                      variant="outlined"
                      sx={{
                        borderRadius: "8px",
                        mt: "10px",
                        position: "relative",
                        overflow: "visible",
                      }}
                    >
                      <CardHeader
                        CardHeader
                        classes={{
                          title: classes.projectCardHeader,
                          subheader: classes.projectCardDes,
                        }}
                        sx={{ cursor: "pointer" }}
                        avatar={
                          <Stack
                            direction="row"
                            spacing={1.7}
                            alignItems="center"
                          >
                            <AdminPanelSettingsRoundedIcon
                              sx={{
                                color: "rgba(0, 0, 0, 0.54)",
                                ml: "-3.5px",
                                fontSize: "30px",
                              }}
                            />
                            {uniqueTeamMembers?.length ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexWrap: "wrap",
                                  mt: "4px !important",
                                  mb: "4px !important",
                                }}
                              >
                                <AvatarGroup
                                  sx={{
                                    "& .MuiAvatarGroup-root": {
                                      margin: "0px !important",
                                    },
                                    "& .MuiAvatarGroup-avatar": {
                                      margin: "-6px !important",
                                    },
                                    "& .MuiAvatar-circular": {
                                      margin: "-6px -12px!important",
                                    },
                                    ml: "4px !important",
                                  }}
                                  max={5}
                                >
                                  {uniqueTeamMembers.map((member) => {
                                    return (
                                      <AvatarComponent
                                        sx={{ width: "40px", height: "40px" }}
                                        title={member.full_name}
                                        nameInitial={member.full_name}
                                        src={
                                          member?.profile_images
                                            ?.profile_img_thumbnail || null
                                        }
                                      />
                                    );
                                  })}
                                </AvatarGroup>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Avatar
                                  src={
                                    project?.team_member?.profile_images
                                      ?.profile_img_thumbnail || null
                                  }
                                  title={project?.team_member?.full_name}
                                  sx={{
                                    height: "39.5px",
                                    width: "39.5px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </Box>
                            )}
                          </Stack>
                        }
                        title={
                          uniqueTeamMembers?.length ? "" : "Admin Unassigned"
                        }
                      />
                    </Card>
                    <Card
                      variant="outlined"
                      sx={{ borderRadius: "8px", mt: "10px", mb: "10px" }}
                    >
                      <CardHeader
                        CardHeader
                        classes={{
                          title: classes.taskCardHeader,
                          subheader: classes.overDueDes,
                        }}
                        sx={{ ml: "2px !important" }}
                        avatar={
                          <AccessTimeRoundedIcon
                            sx={{ color: "grey", ml: "-1px", fontSize: "22px" }}
                          />
                        }
                        title={
                          <Stack direction={"row"} spacing={1}>
                            {calculateDueIn(project?.due_date) === "Overdue"
                              ? ""
                              : "Due in "}
                            <Box
                              sx={{
                                ml:
                                  calculateDueIn(project?.due_date) ===
                                  "Overdue"
                                    ? "0px"
                                    : "5px !important",
                                color:
                                  momentTZ(project?.due_date).diff(
                                    momentTZ(),
                                    "hours"
                                  ) < 24 &&
                                  momentTZ(project?.due_date).diff(
                                    momentTZ(),
                                    "hours"
                                  ) > 0
                                    ? "#FF7A00"
                                    : momentTZ(project?.due_date).diff(
                                        momentTZ(),
                                        "hours"
                                      ) <= 0
                                    ? "red"
                                    : calculateDueIn(project?.due_date) ===
                                      "Overdue"
                                    ? "red"
                                    : "",
                                textTransform:
                                  calculateDueIn(project?.due_date) ===
                                  "Overdue"
                                    ? "uppercase"
                                    : "",
                              }}
                            >
                              {calculateDueIn(project?.due_date)}
                            </Box>
                          </Stack>
                        }
                        subheader={`Due Date: ${moment(
                          project?.due_date
                        ).format("L")} at ${moment(project?.due_date).format(
                          "LT"
                        )}`}
                      />
                    </Card>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={2}
                      maxRows={2}
                      placeholder="Publicly Visible Task Completion Comments"
                      className={classes.textArea2}
                      defaultValue={publicNote}
                      onBlur={(e) => {
                        setPublicNote(e.target.value);
                        dispatch(
                          saveProjectTaskPublicComments({
                            id: project.id,
                            value: e.target.value,
                            type: "project",
                            noteType: "public_notes",
                          })
                        );
                      }}
                    />
                    {projectButton &&
                      (projectButton === "completed" ||
                        projectButton === "canceled") && (
                        <Button
                          className={classes.projectButton}
                          variant="contained"
                          onClick={() => handleChangeStatus(projectButton)}
                        >
                          {projectButton === "completed"
                            ? "Complete"
                            : "Cancel"}{" "}
                          Project
                        </Button>
                      )}
                  </Grid>
                  <Grid xs={12} md={7} lg={7.6} item>
                    <Box sx={{ position: "relative" }}>
                      <LockRoundedIcon
                        sx={{
                          position: "absolute",
                          top: "14px",
                          right: "13px",
                          color: "grey",
                        }}
                      />{" "}
                      {/* <TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        maxRows={7}
                        disabled
                        placeholder="Creation Notes are entered here"
                        className={classes.textAreaForTopRight}
                        value={`${creationNote?.value} https://method-dev.atlassian.net/browse/MP-992`}
                        // sx={{ height: "72.5vh !important" }}
                      /> */}
                      <Box className={classes.textAreaForTopRight}>
                        <Linkify
                          properties={{ target: "_blank" }}
                          componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key
                          ) => (
                            <a target="blank" href={decoratedHref} key={key}>
                              {decoratedText}
                            </a>
                          )}
                        >
                          <pre
                            style={{
                              margin: "0px",
                              whiteSpace: "pre-line",
                              wordBreak: "break-word  !important",
                            }}
                          >
                            {creationNote?.value}
                          </pre>
                        </Linkify>
                      </Box>
                      {specialStips && (
                        <Box sx={{ position: "relative" }}>
                          <LockRoundedIcon
                            sx={{
                              position: "absolute",
                              top: "14px",
                              right: "13px",
                              color: "grey",
                            }}
                          />
                          <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2.5}
                            maxRows={2.5}
                            placeholder="Stipulation Notes"
                            className={classes.textAreaStipulation}
                            defaultValue={specialStips.value}
                            disabled
                            onBlur={(e) => {
                              setSpecialStips({
                                ...specialStips,
                                value: e.target.value,
                              });
                              dispatch(
                                saveProjectDataFields({
                                  id: specialStips.id,
                                  value: e.target.value,
                                })
                              );
                            }}
                          />
                        </Box>
                      )}
                      <Card
                        variant="outlined"
                        sx={{ padding: "13px", borderRadius: "8px", mt: "4px" }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                          sx={{ mb: "15px" }}
                        >
                          <TaskAltRoundedIcon sx={{ color: "grey" }} />{" "}
                          <Typography>Tasks</Typography>
                        </Stack>

                        <Box
                          sx={{
                            height: specialStips ? "268px" : "400px",
                            paddingRight:
                              project?.project_tasks?.length > 4
                                ? "10px"
                                : "0px",
                          }}
                          className={classes.taskList}
                        >
                          {project?.project_tasks?.map((item, index) => (
                            <Card
                              key={item.id}
                              variant="outlined"
                              sx={{
                                mb: "10px",
                                borderRadius: "8px",
                                overflow: "visible",
                              }}
                            >
                              <Box className={classes.taskListItem}>
                                <Box
                                  sx={{
                                    cursor: "pointer",
                                    width: "-webkit-fill-available",
                                  }}
                                  onClick={() => handleOpenTask(item.id)}
                                >
                                  <Typography
                                    className={classes.taskListItemHeading}
                                  >
                                    {item?.task_name}
                                  </Typography>
                                  {item?.task_department?.name ? (
                                    <Box className={classes.taskListItemDes}>
                                      {item?.task_department?.name || ""}
                                    </Box>
                                  ) : null}
                                </Box>
                                <Stack
                                  direction={"row"}
                                  spacing={1.5}
                                  alignItems={"center"}
                                >
                                  {item?.public_notes ? (
                                    <MessageRoundedIcon
                                      onClick={() => handleOpenTask(item.id)}
                                      fontSize="small"
                                      sx={{ color: "grey", cursor: "pointer" }}
                                    />
                                  ) : null}
                                  {item?.task_deliverables?.length ? (
                                    <AttachFileRoundedIcon
                                      onClick={() => handleOpenTask(item.id)}
                                      fontSize="small"
                                      sx={{ color: "grey", cursor: "pointer" }}
                                    />
                                  ) : null}
                                  <OutsideClickHandler
                                    onOutsideClick={() => {
                                      openTaskStatusDialog(false);
                                    }}
                                    disabled={
                                      taskStatusDialog === item.id
                                        ? false
                                        : true
                                    }
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space",
                                        position: "relative",
                                      }}
                                    >
                                      <StatusComponent
                                        height="25px"
                                        // onClick={() =>
                                        //   setTaskStatusDialog((pre) =>
                                        //     pre === item.id ? false : item.id
                                        //   )
                                        // } disable this feature for now
                                        clickable
                                        status={item?.status}
                                      />

                                      {taskStatusDialog === item.id && (
                                        <Grid
                                          className={classes.statusBox}
                                          sx={{ zIndex: 1000 }}
                                        >
                                          {handleReturnTaskStatus(
                                            item?.approval_required
                                          )?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              className={classes.statusBoxItem}
                                              onClick={() =>
                                                handleStatusChange(
                                                  item.value,
                                                  item
                                                )
                                              }
                                            >
                                              <StatusComponent
                                                clickable
                                                status={item.title}
                                                sx={{ padding: "0px 15px" }}
                                                height="32px"
                                                bgColor={item.bgColor}
                                              />
                                            </MenuItem>
                                          ))}
                                        </Grid>
                                      )}
                                    </Box>
                                  </OutsideClickHandler>
                                  <OutsideClickHandler
                                    onOutsideClick={() => {
                                      openTeamMemberDropdown(false);
                                    }}
                                    disabled={
                                      teamMemberDropdown === item.id
                                        ? false
                                        : true
                                    }
                                  >
                                    <Box
                                      sx={{
                                        margin:
                                          "0px 2.5px  0px 2.5px!important",
                                        position: "relative",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <CustomTooltip
                                        title={item?.team_member?.full_name}
                                      >
                                        <Avatar
                                          src={
                                            item?.team_member?.profile_images
                                              ?.profile_img_thumbnail || null
                                          }
                                          title={item?.team_member?.full_name}
                                          sx={{
                                            height: "36px",
                                            width: "36px",
                                            borderRadius: "50%",
                                          }}
                                          onClick={() =>
                                            openTeamMemberDropdown(
                                              teamMemberDropdown === item.id
                                                ? false
                                                : item.id
                                            )
                                          }
                                        />
                                      </CustomTooltip>

                                      {teamMemberDropdown === item.id && (
                                        <Grid className={classes.taskMemberBox}>
                                          {item?.team_member && (
                                            <MenuItem
                                              onClick={() => {
                                                unassignTaskMember(
                                                  item?.team_member_id,
                                                  item
                                                );
                                                openTeamMemberDropdown(false);
                                              }}
                                            >
                                              Unassign Task
                                            </MenuItem>
                                          )}
                                          {users
                                            .filter(
                                              (user) => user.is_agent === false
                                            )
                                            .map((user) => (
                                              <MenuItem
                                                onClick={() => {
                                                  assignTaskMember(user, item);
                                                  openTeamMemberDropdown(false);
                                                }}
                                                value={user.id}
                                              >
                                                <AvatarComponent
                                                  sx={{ marginRight: "14px" }}
                                                  src={
                                                    user.profile_images
                                                      ? user.profile_images
                                                          ?.profile_img_thumbnail
                                                      : null
                                                  }
                                                  nameInitial={user.full_name}
                                                />
                                                {user.full_name}
                                              </MenuItem>
                                            ))}
                                        </Grid>
                                      )}
                                    </Box>
                                  </OutsideClickHandler>
                                  {!openedTasks.includes(item.id) ? (
                                    <IconButton
                                      sx={{
                                        ml: "4px !important",
                                        mr: "0px !important",
                                        padding: "0px",
                                      }}
                                      onClick={() => handleOpenTaskDetail(item)}
                                      size="small"
                                    >
                                      <KeyboardArrowDownRoundedIcon
                                        sx={{
                                          fontSize: "32px",
                                          color:
                                            item?.public_notes ||
                                            item?.task_deliverables?.length
                                              ? "#8F8F8F"
                                              : "#D9D9D9",
                                        }}
                                      />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      sx={{
                                        ml: "4px !important",
                                        mr: "0px !important",
                                        padding: "0px",
                                      }}
                                      onClick={() => {
                                        setOpenedTasks((preTaskIds) =>
                                          preTaskIds.filter(
                                            (id) => id !== item.id
                                          )
                                        );
                                      }}
                                      size="small"
                                    >
                                      <KeyboardArrowUpRoundedIcon
                                        sx={{ fontSize: "32px" }}
                                      />
                                    </IconButton>
                                  )}
                                </Stack>
                              </Box>
                              {openedTasks.includes(item.id) ? (
                                <CardContent
                                  className={classes.taskListItemDetail}
                                >
                                  {item?.public_notes ? (
                                    <Fragment>
                                      <Typography
                                        className={
                                          classes.taskListItemDetailHeading
                                        }
                                      >
                                        Public Notes:
                                      </Typography>
                                      <Box
                                        className={
                                          classes.taskListItemDetailBox
                                        }
                                      >
                                        <Box
                                          className={
                                            classes.taskListItemDetailDescription
                                          }
                                        >
                                          <Linkify
                                            properties={{ target: "_blank" }}
                                            componentDecorator={(
                                              decoratedHref,
                                              decoratedText,
                                              key
                                            ) => (
                                              <a
                                                target="blank"
                                                href={decoratedHref}
                                                key={key}
                                              >
                                                {decoratedText}
                                              </a>
                                            )}
                                          >
                                            <pre
                                              style={{
                                                margin: "0px",
                                                whiteSpace: "pre-line",
                                                wordBreak:
                                                  "break-word  !important",
                                              }}
                                            >
                                              {" "}
                                              {item?.public_notes}{" "}
                                            </pre>
                                          </Linkify>
                                        </Box>
                                      </Box>
                                    </Fragment>
                                  ) : null}
                                  {item?.task_deliverables?.length ? (
                                    <Fragment>
                                      <Typography
                                        sx={{
                                          mt: item?.public_notes ? "10px" : "",
                                        }}
                                        className={
                                          classes.taskListItemDetailHeading
                                        }
                                      >
                                        Attachments:
                                      </Typography>
                                      <Box
                                        className={
                                          classes.taskListItemAttachment
                                        }
                                      >
                                        <Box
                                          className={
                                            classes.taskListItemAttachmentLeft
                                          }
                                        >
                                          <Box
                                            className={
                                              classes.taskListItemAttachmentLeftImages
                                            }
                                          >
                                            {item?.task_deliverables?.map(
                                              (attachment) => (
                                                <CustomTooltip
                                                  placement="top"
                                                  title={getLastPartOfString(
                                                    attachment.file
                                                  )}
                                                >
                                                  <Box
                                                    onClick={() =>
                                                      openDeliverable(
                                                        attachment
                                                      )
                                                    }
                                                    className={
                                                      classes.taskListItemAttachmentLeftImagesItem
                                                    }
                                                  >
                                                    <img
                                                      alt={item?.task_name}
                                                      src={
                                                        attachment?.thumbnail
                                                      }
                                                    />
                                                  </Box>
                                                </CustomTooltip>
                                              )
                                            )}
                                          </Box>
                                          <Box
                                            className={
                                              classes.taskListItemAttachmentLeftDownloadButton
                                            }
                                          >
                                            <Button
                                              onClick={() =>
                                                downloadDeliverable(
                                                  item?.task_deliverables
                                                )
                                              }
                                              className={
                                                classes.taskListItemAttachmentLeftDownloadButtonDown
                                              }
                                            >
                                              <DownloadRoundedIcon
                                                sx={{ color: "grey" }}
                                              />
                                            </Button>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Fragment>
                                  ) : null}
                                </CardContent>
                              ) : null}
                            </Card>
                          ))}
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
            </Grid>
            {openComments ? (
              <Grid
                item
                xs={12}
                lg={openComments ? 3.4 : 12}
                sx={{ paddingLeft: "0px !important" }}
              >
                <CommentSection
                  project={project}
                  comments={project?.project_comments}
                  height={"100%"}
                  handleSubmitComment={handleSubmitComment}
                  id="message-field-projects"
                  {...props}
                />
              </Grid>
            ) : null}
          </Grid>
        )}
      </Dialog>
      <TaskConfirmation
        open={statusAlert}
        setOpen={setStatusAlert}
        handleConfirm={handleConfirm}
        title="Pause Active Task"
        description=" You currently have one other task in “Working” Status. Would you like to pause it?"
      />
      <TaskConfirmation
        open={cancelConfirmation}
        setOpen={setCancelConfirmation}
        handleConfirm={handleConfirmCancel}
        title="Cancel Project"
        description="Are you sure you want to Cancel this Project?"
      />
    </div>
  );
}
