/**
 * Task Actions
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getProjectWithoutRefresh, getProjectList } from "../projects";
import { resetUploadLoading } from "../project";
// for setting current page no of task grid
export const setTaskCurrentPageNo = createAsyncThunk(
  "taskCurrentPageNo/setTaskCurrentPageNo",
  async (data, thunkAPI) => {
    return data;
  }
);

// for getting all tasks
export const getTaskList = createAsyncThunk(
  "taskList/getTaskList",
  async (data, thunkAPI) => {
    let selectedTab = thunkAPI.getState().project.selectedTab;
    let searchText = thunkAPI.getState().project.searchText;
    let pageNo = thunkAPI.getState().project.tasksCurrentPageNo;
    const filterTask = thunkAPI.getState().project.filterTask;
    const pageSize = pageNo * 20;

    try {
      let headers = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      };
      let status;
      let url = `${process.env.REACT_APP_BASE_URL}api/projects/tasks?pageNumber=${pageNo}&pageSize=20`;

      if (selectedTab === 2) {
        url += `&assigned_to_me=1`;
      } else if (data?.departmentID) {
        url += `&department_id=${data.departmentID}`;
      }
      if (searchText.length > 0) {
        url += `&search=${searchText}`;
      }
      if (filterTask.status.length > 0) {
        if (
          filterTask.status.toLowerCase() === "on hold" ||
          filterTask.status.toLowerCase() === "onhold"
        ) {
          status = ["onhold"];
        } else {
          status = [filterTask.status];
        }
        url += `&status=${JSON.stringify(status)}`;
      }

      const resp = await axios.get(url, headers);
      return { data: resp.data };
    } catch (error) {
      console.log(error);
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getKanbanTaskList = createAsyncThunk(
  "taskList/getKanbanTaskList",
  async (data, thunkAPI) => {
    let selectedTab = thunkAPI.getState().project.selectedTab;
    let searchText = thunkAPI.getState().project.searchText;
    let pageNo = thunkAPI.getState().project.tasksCurrentPageNo;
    let status;

    let url;
    if (selectedTab === 1) {
      url = `${process.env.REACT_APP_BASE_URL}api/projects/tasks?pageNumber=${pageNo}&pageSize=250`;
    } else if (selectedTab === 2) {
      url = `${process.env.REACT_APP_BASE_URL}api/projects/tasks?assigned_to_me=1&pageNumber=${pageNo}&pageSize=250`;
    } else if (data.departmentID) {
      url = `${process.env.REACT_APP_BASE_URL}api/projects/tasks?department_id=${data.departmentID}&pageNumber=${pageNo}&pageSize=250`;
    }

    if (searchText.length > 0) {
      url += `&search=${searchText}`;
    }

    try {
      let headers = {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      };
      if (status) {
        headers.params = { status: JSON.stringify(status) };
      }
      const resp = await axios.get(url, headers);
      return { data: resp.data, pageNo };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for getting a particular task in task details dialog
export const getTask = createAsyncThunk(
  "task/getTask",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/projects/tasks/${data}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for getting task history
export const getTaskHistory = createAsyncThunk(
  "taskHistory/getTaskHistory",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/projects/tasks/${data}/history`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for changing Task Status
export const changeTaskStatus = createAsyncThunk(
  "taskStatus/changeTaskStatus",
  async (data, thunkAPI) => {
    try {
      const {
        taskID,
        status,
        shouldFetchTask,
        disableProjectFetch,
        confirmed,
      } = data;
      const { selectedDepartment: departmentID, project } =
        thunkAPI.getState().project;
      let d;
      if (confirmed) {
        d = JSON.stringify({
          status: status,
          confirmed,
        });
      } else {
        d = JSON.stringify({
          status: status,
        });
      }

      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}api/projects/tasks/change-status/${taskID}`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: d,
      };

      await axios(config);
      await thunkAPI.dispatch(setTaskCurrentPageNo(1));

      let taskProject = undefined;
      if (shouldFetchTask) {
        setTimeout(async () => {
          thunkAPI.dispatch(getTaskList({ departmentID }));
        }, 500);
      }
      if (!disableProjectFetch) {
        thunkAPI.dispatch(getProjectList());
      }

      if (project?.id) {
        taskProject = await getProjectWithoutRefresh(project?.id);
      }
      return { ...data, taskProject: taskProject };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

// for claiming a task
export const claimTask = createAsyncThunk(
  "taskClaim/claimTask",
  async (data, thunkAPI) => {
    try {
      const departmentID = thunkAPI.getState().project.selectedDepartment;
      var config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}api/projects/tasks/claim/${data}`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      };

      const resp = await axios(config);
      await thunkAPI.dispatch(setTaskCurrentPageNo(1));
      setTimeout(async () => {
        await thunkAPI.dispatch(getTaskList({ departmentID }));
      }, 500);
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for assigning someone to task
export const assignTask = createAsyncThunk(
  "taskAssign/assignTask",
  async (data, thunkAPI) => {
    const { task_id, user_id, user, disableFetch } = data;
    const departmentID = thunkAPI.getState().project.selectedDepartment;

    var d = JSON.stringify({
      user_id: user_id,
    });

    try {
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}api/projects/tasks/${task_id}/team-member`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: d,
      };

      await axios(config);
      thunkAPI.dispatch(setTaskCurrentPageNo(1));
      if (!disableFetch) {
        setTimeout(() => {
          thunkAPI.dispatch(getTaskList({ departmentID }));
        }, 500);
      }

      return { taskID: task_id, user };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for unassigning from a task
export const unassignTask = createAsyncThunk(
  "taskUnassign/unassignTask",
  async (data, thunkAPI) => {
    const { task_id, member_id } = data;
    const departmentID = thunkAPI.getState().project.selectedDepartment;

    try {
      var config = {
        method: "delete",
        url: `${process.env.REACT_APP_BASE_URL}api/projects/tasks/${task_id}/team-member/${member_id}`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      };

      await axios(config);
      thunkAPI.dispatch(setTaskCurrentPageNo(1));
      setTimeout(() => {
        thunkAPI.dispatch(getTaskList({ departmentID }));
      }, 500);
      return task_id;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for uploading deliverable to a task
export const changeDeliverableLoading = createAsyncThunk(
  // Changing Status of Uploading Deliverable
  "changeDeliverableLoading/changeDeliverableLoading",
  async (data, thunkAPI) => {
    return data;
  }
);

export const upload = async (data, dispatch, handleError) => {
  dispatch(changeDeliverableLoading(true));

  const { taskID, files } = data;

  files.forEach(async (element) => {
    let formData = new FormData();
    formData.append("deliverables", element);
    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_URL}api/projects/tasks/upload-deliverables/${taskID}`,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              "Content-type": "multipart/form-data",
            },
          }
        )
        .then((resp) => {
          setTimeout(function () {
            dispatch(uploadDeliverable(taskID));
          }, 15000);
        });
    } catch (error) {
      dispatch(resetUploadLoading());
      if (error.response) {
        if (handleError) {
          handleError(error?.response?.data?.message || error.response.data);
        }
      } else {
        if (handleError) {
          handleError(error.message);
        }
      }

      console.log(error.response, "error");
    }
  });
};

export const uploadDeliverable = createAsyncThunk(
  // fetch task and task list after uploading deliverable to avoid 403 forbidden error
  "deliverableUpload/uploadDeliverable",
  async (data, thunkAPI) => {
    try {
      const departmentID = thunkAPI.getState().project.selectedDepartment;
      thunkAPI.dispatch(setTaskCurrentPageNo(1));
      setTimeout(() => {
        thunkAPI.dispatch(getTaskList({ departmentID }));
      }, 500);
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/projects/tasks/${data}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return { taskID: data, data: resp?.data?.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for deleting deliverable from a task
export const deleteDeliverable = createAsyncThunk(
  "deleteDeliverable/deliverableDelete",
  async (data, thunkAPI) => {
    const { taskID, deliverableID } = data;
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/projects/tasks/deliverables/${deliverableID}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-type": "multipart/form-data",
          },
        }
      );

      return { taskID: taskID, deliverableID: deliverableID };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for setting task filter status value
export const setTaskFilterStatus = createAsyncThunk(
  "filter/setTaskFilterStatus",
  async (data, thunkAPI) => {
    return data;
  }
);
