import React, { useState, useEffect } from "react";
import ClientInfo from "./clientInfo/clientInfo.jsx";
import { Box, Button, Container, FormGroup } from "@mui/material";
import PropertyInfo from "./proprtyInfo/propertyInfo.jsx";
import Terms from "./Terms/terms.jsx";
import Lender from "./Lender/lender.jsx";
import ClosingAttorney from "./closingAttorney/closingAttorney.jsx";
import CoopAgent from "./CoopAgent/CoopAgent.jsx";
import InterestedParties from "./OtherInterestedParties/otherInterestedParties.jsx";
import ContractTYpe from "./contractType/contractTYpe.jsx";
import ReferralInformation from "./referalContract";
import { useDispatch, useSelector } from "react-redux";
import { addTransaction } from "../../../redux/transactions";
import ResponseAlert from "../../../components/responseAlert";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { validationObj } from "./validation";
import momentTZ from "moment-timezone";
import { getAllPropertyList } from "../../../redux/propertyListing/getAllPropertyListing";
import { getMarkets } from "../../../redux/agents/addRecruitAgent";
let userType = {
  seller0_type: undefined,
  seller0_first_name: undefined,
  seller0_middle_initial: undefined,
  seller0_last_name: undefined,
  seller0_client_phone: undefined,
  seller0_client_email: undefined,
  seller0_entity_name: undefined,
  buyer0_type: undefined,
  buyer0_first_name: undefined,
  buyer0_middle_initial: undefined,
  buyer0_last_name: undefined,
  buyer0_client_phone: undefined,
  buyer0_client_email: undefined,
  buyer0_entity_name: undefined,
  buyer1_entity_name: undefined,
  seller1_type: undefined,
  seller1_first_name: undefined,
  seller1_middle_initial: undefined,
  seller1_last_name: undefined,
  seller1_client_phone: undefined,
  seller1_client_email: undefined,
  seller1_entity_name: undefined,
  buyer1_type: undefined,
  buyer1_first_name: undefined,
  buyer1_middle_initial: undefined,
  buyer1_last_name: undefined,
  buyer1_client_phone: undefined,
  buyer1_client_email: undefined,
  seller0_entity_phone: "",
  seller0_entity_email: "",
  buyer0_entity_phone: "",
  buyer0_entity_email: "",
  seller1_entity_phone: "",
  seller1_entity_email: "",
  buyer1_entity_phone: "",
  buyer1_entity_email: "",
  market_id: "",
};
const AddTransactions = (props) => {
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const [contractDetail, setContractDetail] = useState({
    contract_type: "",
    ...userType,
    agent_name: "",
    agent_id: "",
    attached_listing: "",
    property_type: "",
    city: "",
    state: "",
    property_address: "",
    street: "",
    zipcode: "",
    lot_number: "",
    unit_number: "",
    opposite_party_name: "",
    price: "",
    binding_date: "",
    closing_date: "",
    due_dilligence_days: "",
    financing_type: "",
    finance_contingency_days: "",
    appraisal_contingency_days: "",
    earnest_money_amount: "",
    earnest_money_holder: "",
    earnest_money_due_date: "",
    // lender_company_email: "",
    lender_company_name: "",
    loan_officer_email: "",
    loan_officer_first_name: null,
    loan_officer_last_name: null,
    loan_officer_phone: null,
    closing_attorney_company: "",
    closing_attorney_email: "",
    closing_attorney_first_name: "",
    closing_attorney_last_name: "",
    closing_attorney_phone: "",
    co_op_agent_company: "",
    // co_op_agent_id: "",
    co_op_brokerage_name: "",
    co_op_email: "",
    co_op_first_name: "",
    co_op_last_name: "",
    co_op_phone: "",
    market_id: "",
  });
  let navigate = useNavigate();
  let { state } = useLocation();
  const dispatch = useDispatch();
  const addTransactions = useSelector(
    (state) => state.transactions.TransactionsList.addTransaction
  );
  const markets = useSelector((state) => state.agentList.markets);
  const adminAuth = useSelector((state) => state.adminAuth);
  useEffect(() => {
    if (state?.id) {
      setContractDetail({
        ...contractDetail,
        contract_type: state.is_rental ? "landlord" : "seller",
        agent_name: state.agent.full_name,
        agent_id: state.agent_id,
        attached_listing: state.id,
        city: state.city,
        state: state.state,
        property_address: state.property_address,
        street: state.street,
        zipcode: Number.isInteger(state.zipcode)
          ? state.zipcode.toString()
          : state.zipcode,
      });
    }
  }, []);

  useEffect(() => {
    if (!markets || markets.length === 0) {
      dispatch(getMarkets());
    }
  }, []);

  useEffect(() => {
    if (addTransactions.success && !addTransactions.isLoading) {
      setErrorAlert({
        errorMsg: "You have successfully added the Transaction",
        errorType: "success",
        isOpen: true,
      });
      setTimeout(() => {
        navigate("/transaction/contracts/active-contracts");
      }, 2000);
    } else if (!addTransactions.isLoading && addTransactions.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(addTransactions.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [addTransactions.isLoading]);

  const updateContractDetail = (field, value, field1, value1) => {
    if (field === "contract_type") {
      setContractDetail({
        ...contractDetail,
        [field]: value,
        ...userType,
      });
      setErrMsg({ ...errMsg, [field]: "" });
    } else {
      if (field1) {
        setContractDetail({
          ...contractDetail,
          [field]: value,
          [field1]: value1,
        });
        setErrMsg({ ...errMsg, [field]: "", [field1]: "" });
      } else {
        setContractDetail({
          ...contractDetail,
          [field]: value,
        });
        setErrMsg({ ...errMsg, [field]: "" });
      }
    }
  };

  useEffect(() => {
    if (contractDetail.contract_type) {
      if (contractDetail.contract_type === "landlord") {
        dispatch(getAllPropertyList({ is_rental: 1 }));
      } else {
        dispatch(getAllPropertyList({}));
      }
    }
  }, [contractDetail.contract_type]);
  const handleSubmit = () => {
    if (handleValidate()) {
      let contractDetail_new = { ...contractDetail };
      if (
        contractDetail.contract_type === "buyer" ||
        contractDetail.contract_type === "tenant"
      ) {
        delete contractDetail_new.attached_listing;
      }
      if (contractDetail.contract_type === "referral") {
        delete contractDetail_new.binding_date;
        delete contractDetail_new.earnest_money_due_date;
      }
      Object.keys(contractDetail_new).forEach((k) => {
        if (!contractDetail_new[k]) {
          delete contractDetail_new[k];
        }
        if (k?.split("_")[k?.split("_")?.length - 1] === "date") {
          contractDetail_new[k] = momentTZ
            .tz(
              contractDetail_new[k],
              adminAuth?.adminDetail?.market?.timezone ||
                process.env.REACT_APP_TIMEZONE
            )
            .utc()
            .format();
        }
      });
      dispatch(
        addTransaction({
          ...contractDetail_new,
          property_address: `${contractDetail.street} ${contractDetail.city}  ${contractDetail.state}, ${contractDetail.zipcode} `,
        })
      );
    }
  };
  const handleValidate = () => {
    let isFormValid = true;
    let errorMsg = {};
    if (!contractDetail.contract_type) {
      document.getElementById("contract_type")?.scrollIntoView({
        block: "end",
        inline: "nearest",
        behavior: "smooth",
      });
      setErrorAlert({
        errorMsg: `Contract Type is required`,
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["contract_type"] = `Contract Type is required`;
      setErrMsg(errorMsg);
      isFormValid = false;
      return false;
    }
    if (!contractDetail.market_id) {
      document.getElementById("market_id")?.scrollIntoView({
        block: "end",
        inline: "nearest",
        behavior: "smooth",
      });
      setErrorAlert({
        errorMsg: `Market is required`,
        errorType: "warning",
        isOpen: true,
      });
      errorMsg["market_id"] = `Market Type is required`;
      setErrMsg(errorMsg);
      isFormValid = false;
      return false;
    }
    Object.keys(contractDetail)
      .reverse()
      .forEach((k) => {
        if (
          !contractDetail[k] &&
          validationObj[contractDetail.contract_type][k] &&
          !(
            contractDetail.financing_type === "cash" &&
            k === "lender_company_name"
          )
        ) {
          document.getElementById(k)?.scrollIntoView({
            block: "end",
            inline: "nearest",
            behavior: "smooth",
          });
          errorMsg[k] = `${k.replace("_", " ")} is required`;
          setErrMsg(errorMsg);
          setErrorAlert({
            errorMsg: `${k.replace("_", " ")} is required`,
            errorType: "warning",
            isOpen: true,
          });
          isFormValid = false;
          return false;
        }
      });
    return isFormValid;
  };

  /*{momentTZ.tz(
   contractDetail?.binding_date,
   adminAuth?.adminDetail?.market?.timezone ||
   process.env.REACT_APP_TIMEZONE
   )
   .format("L")} */

  return (
    <Container maxWidth="xl" container sx={{ marginTop: "40px" }}>
      <Box>
        <FormGroup>
          <ContractTYpe
            contractDetail={contractDetail}
            errMsg={errMsg}
            updateContractDetail={updateContractDetail}
            markets={markets}
            {...props}
          />
          {contractDetail.contract_type !== "referral" ? (
            <Box>
              <ClientInfo
                updateContractDetail={updateContractDetail}
                errMsg={errMsg}
                contractDetail={contractDetail}
                {...props}
              />
              <PropertyInfo
                updateContractDetail={updateContractDetail}
                errMsg={errMsg}
                contractDetail={contractDetail}
                {...props}
              />
              <Terms
                updateContractDetail={updateContractDetail}
                errMsg={errMsg}
                contractDetail={contractDetail}
                {...props}
              />
              <Lender
                updateContractDetail={updateContractDetail}
                errMsg={errMsg}
                contractDetail={contractDetail}
                setContractDetail={setContractDetail}
                {...props}
              />
              <ClosingAttorney
                updateContractDetail={updateContractDetail}
                setContractDetail={setContractDetail}
                errMsg={errMsg}
                contractDetail={contractDetail}
                {...props}
              />
              <CoopAgent
                updateContractDetail={updateContractDetail}
                errMsg={errMsg}
                contractDetail={contractDetail}
                {...props}
              />
              <InterestedParties
                updateContractDetail={updateContractDetail}
                errMsg={errMsg}
                contractDetail={contractDetail}
                {...props}
              />
            </Box>
          ) : (
            <ReferralInformation
              updateContractDetail={updateContractDetail}
              errMsg={errMsg}
              contractDetail={contractDetail}
              {...props}
            />
          )}

          <Box sx={{ textAlign: "right", mt: 7, mr: 4 }}>
            <Button
              variant="outlined"
              sx={{
                color: "black",
                backgroundColor: "transpirent",
                border: "none",
                "&:hover": {
                  backgroundColor: "transpirent",
                  color: "black",
                },
              }}
              onClick={() => navigate("/transactions/active-contracts")}
              color="inherit"
            >
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              sx={{
                marginLeft: "15px",
                color: "white",
                backgroundColor: "black",
                border: "none",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              type="submit"
              onClick={handleSubmit}
              color="inherit"
              loadingPosition="start"
              loading={addTransactions.isLoading}
            >
              SUBMIT
            </LoadingButton>
          </Box>
        </FormGroup>
      </Box>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Container>
  );
};

export default AddTransactions;
